<template>
    <div class="q-pa-md top-level row q-gutter-md justify-evenly items-stretch">

        <!-- carousels -->
        <q-card class="my-card" v-for="(doc, idx) in carousels" :key="'carousels_'+idx">
            <q-carousel v-model="doc.slide_nb" :navigation="doc.props.navigation" :arrows="doc.props.navigation"
                swipeable animated infinite :autoplay="2500">
                <q-carousel-slide v-for="(slide, idx2) in doc.slides" :name="idx2" :img-src="slide" :key="'slide_'+idx+'_'+idx2" />
            </q-carousel>
            <q-card-section style="flex:0 0;">
                <div class="text-h6" v-html="doc.title" />
                <div class="text-subtitle2" v-html="doc.subtitle" />
            </q-card-section>
        </q-card>

        <!-- steady pics -->
        <q-card class="my-card" v-for="(doc, idx) in steadyPics" :key="'steady_'+idx">
            <q-card-section style="flex:1 1; min-height:250px; text-align: center;">
                <img :src="doc.picture" >
            </q-card-section>
            <q-card-section style="flex:0 0;">
                <div class="text-h6" v-html="doc.title" />
                <div class="text-subtitle2" v-html="doc.description" />
            </q-card-section>
        </q-card>

    </div>
</template>

<script>
export default ({
    name: 'Designs',
    data() {
        return {
            carousels: [
                {
                    slide_nb: 0,
                    props: {
                        navigation: true,
                    },
                    slides: [
                        '/static/designs/flyer_creavert_recto.png',
                        '/static/designs/flyer_creavert_verso.png',
                    ],
                    title: 'Créa-Vert',
                    subtitle: 'Flyer to promote a company active in gardens creation and maintenance.',
                },
                {
                    slide_nb: 0,
                    props: {
                        navigation: true,
                    },
                    slides: [
                        '/static/designs/flyer_zik-zak_recto.png',
                        '/static/designs/flyer_zik-zak_verso.png',
                    ],
                    title: 'Zik-zak',
                    subtitle: 'Flyer to promote a music school.',
                },
                {
                    slide_nb: 0,
                    props: {
                        navigation: true,
                    },
                    slides: [
                        '/static/designs/flyer_e165_recto.png',
                        '/static/designs/flyer_E165_verso.png',
                    ],
                    title: 'Espace 165',
                    subtitle: 'Flyer to promote a yoga class, along with the renting of the yoga dojo.',
                },
                {
                    slide_nb: 0,
                    props: {
                        navigation: true,
                    },
                    slides: [
                        '/static/designs/djembe_attitude.png',
                        '/static/designs/djembe_attitude_non_retenu.png',
                    ],
                    title: 'Djembé Attitude',
                    subtitle: 'Miscellaneous logos created for a djembe players band.',
                },
                {
                    slide_nb: 0,
                    props: {
                        navigation: true,
                    },
                    slides: [
                        '/static/designs/sysmito_t2_h.png',
                        '/static/designs/sysmito_bolt.png',
                        '/static/designs/t-shirt-sysmita.png',
                    ],
                    title: 'Sysmito',
                    subtitle: 'T-shirt prototypes for a music band.',
                },
                {
                    slide_nb: 0,
                    props: {
                        navigation: false,
                    },
                    slides: [
                        '/static/designs/verso_carte_de_cours.png',
                    ],
                    title: 'Espace 165',
                    subtitle: '10-lessons voucher for the yoga class.',
                },
            ],
            steadyPics: [
                {
                    picture: '/static/designs/creche-saint-charles.png',
                    title: 'Crèche Saint-Charles',
                    description: 'Design of the logo for the nursery.',
                },
                {
                    picture: '/static/designs/eyerlogo_150x47.png',
                    title: 'CREATIVE Associates',
                    description: 'Logo for the software plugin named <em>Eyer</em>.',
                },
                // - design: concert à l'os à moëlle
                // - design: affiche voeux de la 9e
 
            ]
        };
    },
})
</script>

<style scoped lang="less">
.top-level {
    padding: 6px;
}
.my-card {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    border-radius: 5px;
    margin-bottom: 6px;
}
.q-carousel {
    max-height: 250px !important;
    min-height: 250px !important;
    width: auto;
}
</style>
