import { render, staticRenderFns } from "./Designs.vue?vue&type=template&id=98128042&scoped=true&"
import script from "./Designs.vue?vue&type=script&lang=js&"
export * from "./Designs.vue?vue&type=script&lang=js&"
import style0 from "./Designs.vue?vue&type=style&index=0&id=98128042&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98128042",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCarousel,QCarouselSlide,QCardSection});
